main {
  padding: 0 5% 6% 5%; }

noscript.loaded + img {
  display: none; }

article {
  p, hr {
    margin-bottom: 4%; } }

article {
  p:last-child, hr:last-child {
    margin-bottom: 0; } }

.article {
  .article-text {
    max-width: 1200px;
    margin: auto;

    ul, ol {
      margin-bottom: 4%; }

    *:last-child {
      margin-bottom: 0; }

    ul, ol {
      list-style-position: inside;

      li {
        padding: 1% 0; }

      ul, ol {
        margin-bottom: 0;
        padding: 0;
        padding-left: 3%; } }

    blockquote p {
      margin-bottom: 0; }

    h3, h4, h5, h6 {
      font: {
        size: 1.4rem;
        family: $secondary-font; }
      margin-bottom: 1%;
      color: $blue; }

    h4 {
      font-size: 1.2rem;
      margin-bottom: 0.5%; }

    h5 {
      font-size: 1rem;
      margin-bottom: 0.25%; }

    h6 {
      font-size: 1rem;
      margin-bottom: 0.125%;
      color: $main-font-color; }

    .margin-top {
      margin-top: 4%; } } }

// Global Media Queries //
@import 'media/_media_global';
