@media only screen and (max-width: 1024px) {
  .header {
    height: auto;
    flex-direction: column;
    position: relative;
    padding: 10px 2%; } }

@media only screen and (max-width: 860px) {
  .header {
    padding: 12px 2.4%; } }

@media only screen and (max-width: 600px) {
  .header {
    padding: 15px 2.7%; } }

@media only screen and (max-width: 400px) {
  .header {
    padding: 18px 3.2%; } }
