* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

html {
  scroll-behavior: smooth; }

html, body {
  height: 100%;
  font-size: 20px;
  font-family: $main-font;
  line-height: 1.4;
  color: $main-font-color; }

body {
  background-color: $white-gray; }

.clearfix::after {
  content: ".";
  display: block;
  clear: both;
  font-size: 0;
  height: 0;
  visibility: hidden; }

h1, h2, h3, h4, h5, h6 {
  font-family: $secondary-font; }

img {
  display: block;
  max-width: 100%;
  height: auto; }

a {
  text-decoration: none;
  color: $orange;
  transition: all .2s linear; }
