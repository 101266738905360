@media only screen and (max-width: 1024px) {
  .errors {
    p, ul {
      margin-bottom: 3%; } } }

@media only screen and (max-width: 700px) {
  .errors {
    p, ul {
      margin-bottom: 4%; } } }

@media only screen and (max-width: 500px) {
  main {
    padding: 0 3% 9% 3%; }

  .errors {
    p, ul {
      margin-bottom: 5%; } } }

@media only screen and (max-width: 450px) {
  main {
    padding: 0 3% 10% 3%; }

  .errors {
    p, ul {
      margin-bottom: 6%; } } }

@media only screen and (max-width: 360px) {
  main {
    padding: 0 5% 10% 5%; } }
