// General Styles //
@import '../_style';

// Page Styles //
.errors {
  p, ul {
    margin-bottom: 2%; }

  p:last-child {
    margin-bottom: 0; }

  ul, ol {
    list-style-position: inside; }

  ul {
    list-style-type: disc; } }

// Media Queries Errors Page //
@import '../media/_media_errors';
