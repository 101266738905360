@media only screen and (max-width: 1024px) {
  main {
    padding: 0 6% 7% 6%; }

  .article .article-text {
    h3 {
      margin-bottom: 2%; }

    h4 {
      margin-bottom: 1.5%; }

    h5 {
      margin-bottom: 1.25%; }

    h6 {
      margin-bottom: 1.125%; } } }

@media only screen and (max-width: 700px) {
  main {
    padding: 0 7% 8% 7%; }

  .article .article-text {
    h3 {
      margin-bottom: 3%; }

    h4 {
      margin-bottom: 2.5%; }

    h5 {
      margin-bottom: 2.25%; }

    h6 {
      margin-bottom: 2.125%; } } }

@media only screen and (max-width: 500px) {
  main {
    padding: 0 0 9% 0; }

  article {
    p, hr {
      margin-bottom: 5%; } }

  .article .article-text {
    > p, ul, ol {
      padding: 0 3%;
      margin-bottom: 5%;

      li {
        padding: 2% 0; }

      ul, ol {
        padding-left: 4%; } }

    h3, h4, h5, h6 {
      padding: 0 3%; }

    h3 {
      margin-bottom: 4%; }

    h4 {
      margin-bottom: 3.5%; }

    h5 {
      margin-bottom: 3.25%; }

    h6 {
      margin-bottom: 3.125%; }

    .margin-top {
      margin-top: 5%; } } }

@media only screen and (max-width: 450px) {
  main {
    padding: 0 0 10% 0; }

  .article .article-text {
    h3 {
      margin-bottom: 5%; }

    h4 {
      margin-bottom: 4.5%; }

    h5 {
      margin-bottom: 4.25%; }

    h6 {
      margin-bottom: 4.125%; } } }

@media only screen and (max-width: 400px) {
  html, body {
    font-size: 18px; }

  article {
    p, hr {
      margin-bottom: 8%; } }

  .article .article-text {
    > p, ul, ol {
      margin-bottom: 8%;

      li {
        padding: 3% 0; } }

    .margin-top {
      margin-top: 8%; } } }

@media only screen and (max-width: 360px) {
  .article .article-text {
    > p, ul, ol {
      padding: 0 5%;

      ul, ol {
        padding-left: 5%; } }

    h3, h4, h5, h6 {
      padding: 0 5%; } } }

@media only screen and (max-width: 350px) {
  html, body {
    font-size: 16px; } }
