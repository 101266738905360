.header {
  height: 80px;
  background-color: white;
  padding: 0 2%;
  display: flex;
  justify-content: space-between;
  align-items: center; }

// Media Queries Header //
@import '../media/_media_header';
